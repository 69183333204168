import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { Grid } from './styled'
import 'react-image-lightbox/style.css'

type Props = {
  columns?: number
  images: {
    src: string
    wide?: boolean
  }[]
}

const VISIBLE_IN_VIEWPORT_NUM = 6

const PhotoProject = ({ images, columns }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)

  return (
    <>
      <Grid columns={columns}>
        {images.map((image, index) => (
          <img
            src={image.src}
            onClick={() => setSelectedIndex(index)}
            key={image.src}
            data-wide={image.wide}
            loading={index > VISIBLE_IN_VIEWPORT_NUM ? 'lazy' : undefined}
          />
        ))}
      </Grid>
      {selectedIndex >= 0 && (
        <Lightbox
          animationDisabled={true}
          mainSrc={images[selectedIndex].src}
          nextSrc={images[(selectedIndex + 1) % images.length].src}
          prevSrc={
            images[(selectedIndex + images.length - 1) % images.length].src
          }
          onCloseRequest={() => setSelectedIndex(-1)}
          onMovePrevRequest={() =>
            setSelectedIndex(
              (selectedIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setSelectedIndex((selectedIndex + 1) % images.length)
          }
        />
      )}
    </>
  )
}

export default PhotoProject
