import styled from 'styled-components'
import mq from 'styles/mq'

export const Grid = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  padding: 2rem 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

    &[data-wide='true'] {
      grid-column: span 2;
    }
  }

  ${mq.medium} {
    gap: 0.5rem;
    grid-template-columns: repeat(${props => props.columns ?? 3}, 1fr);
  }

  ${mq.large} {
    margin: 0 -20rem;
  }
`
